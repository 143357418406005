#about {

.padding {padding: 50px 20px; }



.show-center-text-hidden {text-align: center; margin-top: 40px;
&.active {display: none;}
}
.show-center-text-hidden a {color:#d8b161; text-decoration: underline; padding: 10px 20px;

&:hover {text-decoration: none;}
 }

.center-text-hidden {display: none;}
.center-text-hidden.open {display: block;}

} /* #about */



#main-gallery {

.padding {padding-bottom:20px;}

.owl-item {margin:0px 1px;}

 ul.proizvod-gallery {font-size: 0; margin-bottom: 40px;}
 ul.proizvod-gallery li {

display: inline-block; margin:1px; width:calc(25% - 8px);

				// < 479
				@include respond-to(handhelds) { width:calc(100% - 2px); }
				// 480 > 599
				@include respond-to(wide-handhelds) { width:calc(50% - 2px); }
				// 600 > 767
				@include respond-to(small-tablets) { width:calc(33.3% - 6px); }
				// 768 > 979
				@include respond-to(tablets) { width:calc(33.3% - 6px); }
				// 980 < 
				@include respond-to(display) {  }
				// 1200 < 
				@include respond-to(display-big) { }


}

 ul.proizvod-gallery li img {max-width: 100%;}
 ul.proizvod-owl li img {max-width: 100%;}

.show-all-gallery {text-align: center; margin: 40px 0px;
&.active {display: none;}
}
.show-all-gallery a {color:#d8b161; text-decoration: underline; padding: 10px 20px;

&:hover {text-decoration: none;}
 }

.show-all-gallery.active {display: none;}

ul.proizvod-gallery li a,  ul.proizvod-owl li a {position:relative; display:block;}
ul.proizvod-gallery li a span,  ul.proizvod-owl li a span {position: absolute; width: 100%; height: 100%; background: url(../img/play-button-gold-2.png) center center; background-size: cover; transition: all 0.3s linear;
&:hover {background: url(../img/play-button-grey-2.png) center center; background-size: cover; transition: all 0.3s linear;}

}

.proizvod-gallery {display: none;}
.proizvod-gallery.open {display: block;}

.proizvod-owl.close {display: none!important;}

} /*  #main-gallery */




