@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

/* Код для подключения шрифта в /css/stylename.css */
/* font-family: "OpenSansRegular"; */
@font-face {
  font-family: "OpenSansRegular";
  src: url("../fonts/OpenSansRegular/OpenSansRegular.eot");
  src: url("../fonts/OpenSansRegular/OpenSansRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansRegular/OpenSansRegular.woff") format("woff"), url("../fonts/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "OpenSansLight"; */
@font-face {
  font-family: "OpenSansLight";
  src: url("../fonts/OpenSansLight/OpenSansLight.eot");
  src: url("../fonts/OpenSansLight/OpenSansLight.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansLight/OpenSansLight.woff") format("woff"), url("../fonts/OpenSansLight/OpenSansLight.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "OpenSansItalic"; */
@font-face {
  font-family: "OpenSansItalic";
  src: url("../fonts/OpenSansItalic/OpenSansItalic.eot");
  src: url("../fonts/OpenSansItalic/OpenSansItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansItalic/OpenSansItalic.woff") format("woff"), url("../fonts/OpenSansItalic/OpenSansItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "OpenSansBold"; */
@font-face {
  font-family: "OpenSansBold";
  src: url("../fonts/OpenSansBold/OpenSansBold.eot");
  src: url("../fonts/OpenSansBold/OpenSansBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansBold/OpenSansBold.woff") format("woff"), url("../fonts/OpenSansBold/OpenSansBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "OpenSansExtraBold"; */
@font-face {
  font-family: "OpenSansExtraBold";
  src: url("../fonts/OpenSansExtraBold/OpenSansExtraBold.eot");
  src: url("../fonts/OpenSansExtraBold/OpenSansExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansExtraBold/OpenSansExtraBold.woff") format("woff"), url("../fonts/OpenSansExtraBold/OpenSansExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "OpenSansExtraBoldItalic"; */
@font-face {
  font-family: "OpenSansExtraBoldItalic";
  src: url("../fonts/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot");
  src: url("../fonts/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.woff") format("woff"), url("../fonts/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "OpenSansBoldItalic"; */
@font-face {
  font-family: "OpenSansBoldItalic";
  src: url("../fonts/OpenSansBoldItalic/OpenSansBoldItalic.eot");
  src: url("../fonts/OpenSansBoldItalic/OpenSansBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansBoldItalic/OpenSansBoldItalic.woff") format("woff"), url("../fonts/OpenSansBoldItalic/OpenSansBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "OpenSansRegularItalic"; */
@font-face {
  font-family: "OpenSansRegularItalic";
  src: url("../fonts/OpenSansRegularItalic/OpenSansRegularItalic.eot");
  src: url("../fonts/OpenSansRegularItalic/OpenSansRegularItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansRegularItalic/OpenSansRegularItalic.woff") format("woff"), url("../fonts/OpenSansRegularItalic/OpenSansRegularItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "OpenSansSemiBold"; */
@font-face {
  font-family: "OpenSansSemiBold";
  src: url("../fonts/OpenSansSemiBold/OpenSansSemiBold.eot");
  src: url("../fonts/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansSemiBold/OpenSansSemiBold.woff") format("woff"), url("../fonts/OpenSansSemiBold/OpenSansSemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "OpenSansSemiBoldItalic"; */
@font-face {
  font-family: "OpenSansSemiBoldItalic";
  src: url("../fonts/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot");
  src: url("../fonts/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.woff") format("woff"), url("../fonts/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

body {
  font-family: "OpenSansRegular", sans-serif;
  background: #000; }

.clear {
  clear: both; }

.none {
  line-height: 0; }

#header.header-fon-big {
  background: url("../img/header-fon-big.jpg") bottom center; }

#header.header-fon-sm {
  background: url("../img/header-fon-big.jpg") bottom center; }

#header {
  min-height: 220px;
  /* header-sm_btns */ }
  @media only screen and (max-width: 479px) {
    #header {
      height: 220px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #header {
      height: 320px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #header {
      height: 320px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #header.header-fon-big {
      height: 480px; } }
  @media only screen and (min-width: 980px) {
    #header.header-fon-big {
      height: 600px; } }
  #header .header-line {
    background: rgba(0, 0, 0, 0.6); }
  #header .pod-block {
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
    @media only screen and (max-width: 479px) {
      #header .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #header .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #header .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 980px) {
      #header .pod-block {
        max-width: 1200px; } }
    #header .pod-block .padding {
      padding: 0px 20px; }
    #header .pod-block .header-logo {
      float: left;
      margin: 10px 0px; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-logo {
          width: 160px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #header .pod-block .header-logo {
          width: 220px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .header-logo {
          width: 220px; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #header .pod-block .header-logo {
          width: 220px; } }
    #header .pod-block .header-logo img {
      width: 100%; }
    #header .pod-block .header-menu {
      float: right; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-menu {
          display: none; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #header .pod-block .header-menu {
          display: none; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .header-menu {
          display: none; } }
    #header .pod-block .header-menu .close_menu {
      display: none; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-menu .close_menu {
          display: block; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #header .pod-block .header-menu .close_menu {
          display: block; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .header-menu .close_menu {
          display: block; } }
    #header .pod-block .header-menu {
      text-align: justify;
      /* Лекарство для IE6-7*/
      text-justify: newspaper; }
    #header .pod-block .header-menu ul li {
      display: inline-block;
      line-height: 100px;
      margin-right: 10px; }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #header .pod-block .header-menu ul li {
          margin-right: 5px; } }
    #header .pod-block .header-menu ul li a {
      color: #c7c7c7;
      line-height: 100px;
      font-size: 16px;
      text-decoration: none; }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #header .pod-block .header-menu ul li a {
          font-size: 14px; } }
    #header .pod-block .header-menu ul li a:hover {
      border-bottom: 1px solid #c7c7c7; }
    #header .pod-block .header-menu ul li.hidden {
      width: 100%;
      height: 0;
      visibility: hidden; }
    #header .pod-block .header-sm_btns {
      display: none; }
      #header .pod-block .header-sm_btns > a {
        color: #c7c7c7;
        line-height: 100px;
        font-size: 18px;
        text-align: center; }
        @media only screen and (max-width: 479px) {
          #header .pod-block .header-sm_btns > a {
            line-height: 75px; } }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-sm_btns {
          display: block;
          float: right; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #header .pod-block .header-sm_btns {
          display: block;
          float: right; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .header-sm_btns {
          display: block;
          float: right; } }

/* #header*/
@media only screen and (max-width: 767px) {
  #top-menu.header-menu.active {
    position: absolute;
    top: 77px;
    left: 0px;
    width: 100%;
    text-align: justify;
    /* Лекарство для IE6-7*/
    text-justify: newspaper;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
    padding-top: 20px; } }
  @media only screen and (max-width: 767px) and (max-width: 479px) {
    #top-menu.header-menu.active {
      display: block; } }
  @media only screen and (max-width: 767px) and (min-width: 480px) and (max-width: 599px) {
    #top-menu.header-menu.active {
      display: block;
      top: 102px; } }
  @media only screen and (max-width: 767px) and (min-width: 600px) and (max-width: 767px) {
    #top-menu.header-menu.active {
      display: block;
      top: 102px; } }

@media only screen and (max-width: 767px) {
  #top-menu.header-menu.active ul li {
    display: inline-block;
    line-height: 32px;
    margin: 0 25px 0 25px;
    font-size: 18px; } }
  @media only screen and (max-width: 767px) and (max-width: 479px) {
    #top-menu.header-menu.active ul li {
      display: block;
      width: 70%;
      margin: 0 15% 20px 15%; } }
  @media only screen and (max-width: 767px) and (min-width: 480px) and (max-width: 599px) {
    #top-menu.header-menu.active ul li {
      display: block;
      width: 70%;
      margin: 0 15% 20px 15%; } }
  @media only screen and (max-width: 767px) and (min-width: 600px) and (max-width: 767px) {
    #top-menu.header-menu.active ul li {
      display: block;
      width: 70%;
      margin: 0 15% 20px 15%; } }

@media only screen and (max-width: 767px) {
  #top-menu.header-menu.active ul li a {
    border: 1px solid #d8b161;
    display: block;
    padding: 10px 10px;
    color: #d8b161;
    line-height: 32px;
    width: calc(100% - 20px);
    text-decoration: none;
    background: #000; }
  #top-menu.header-menu.active ul li a:hover {
    color: #fff;
    border: 1px solid #fff; }
  #top-menu.header-menu.active a.hidden {
    width: 100%;
    height: 0;
    visibility: hidden; } }

/*Анимация гамбургера*/
.header-sm_btns_text {
  display: inline-block;
  vertical-align: middle; }

.cmn-toggle-switch {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 15px;
  height: 13px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s; }

.cmn-toggle-switch:focus {
  outline: none; }

.cmn-toggle-switch span {
  display: block;
  position: absolute;
  top: 5px;
  left: 0px;
  right: 0px;
  height: 3px;
  background: #c7c7c7; }

.cmn-toggle-switch span::before,
.cmn-toggle-switch span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #c7c7c7;
  content: ""; }

.cmn-toggle-switch span::before {
  top: -5px; }

.cmn-toggle-switch span::after {
  bottom: -5px; }

/**
 * Стиль 2
 * 
 * «Гамбургер» превращается в «x» (класс htx). 
 * Берется исходное состояние, затем полоски плавно перемещаются в центр 
 * и трансформируются в «x».
*/
.cmn-toggle-switch__htx {
  background-color: transparent; }

.cmn-toggle-switch__htx span {
  /*   -webkit-transition: background 0 0.3s;
        transition: background 0 0.3s; */ }

.cmn-toggle-switch__htx span::before,
.cmn-toggle-switch__htx span::after {
  -webkit-transition-duration: 0.3s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  -webkit-transition-delay: 0.3s, 0;
  transition-delay: 0.3s, 0; }

.cmn-toggle-switch__htx span::before {
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, transform; }

.cmn-toggle-switch__htx span::after {
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform; }

/* активное состояние, т.е. меню открыто */
.active > .cmn-toggle-switch__htx {
  background-color: transparent; }

.active > .cmn-toggle-switch__htx span {
  background: none; }

.active > .cmn-toggle-switch__htx span::before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.active > .cmn-toggle-switch__htx span::after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.active > .cmn-toggle-switch__htx span::before,
.active > .cmn-toggle-switch__htx span::after {
  -webkit-transition-delay: 0, 0.3s;
  transition-delay: 0, 0.3s; }

#footer {
  border-top: 2px solid #5a5a5a;
  background: #0d0d0d; }
  #footer .footer-block-top .footer-block-right {
    float: right;
    width: 65%;
    margin-right: 2%; }
    @media only screen and (max-width: 479px) {
      #footer .footer-block-top .footer-block-right {
        float: none;
        width: 90%;
        margin: 0 5%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #footer .footer-block-top .footer-block-right {
        float: none;
        width: 90%;
        margin: 0 5%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #footer .footer-block-top .footer-block-right {
        float: none;
        width: 90%;
        margin: 0 5%; } }
    #footer .footer-block-top .footer-block-right .footer-logo {
      text-align: center;
      margin: 40px 0px 60px 0px; }
    #footer .footer-block-top .footer-block-right .footer-logo img {
      max-width: 100%; }
    #footer .footer-block-top .footer-block-right .footer-menu {
      text-align: justify;
      /* Лекарство для IE6-7*/
      text-justify: newspaper;
      margin-bottom: -75px; }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #footer .footer-block-top .footer-block-right .footer-menu {
          margin-bottom: 40px; } }
      @media only screen and (min-width: 980px) {
        #footer .footer-block-top .footer-block-right .footer-menu {
          margin-bottom: 40px; } }
    #footer .footer-block-top .footer-block-right .footer-menu ul li {
      display: inline-block;
      line-height: 130%;
      margin-right: 10px; }
    #footer .footer-block-top .footer-block-right .footer-menu ul li a {
      color: #c7c7c7;
      line-height: 130%;
      font-size: 16px;
      text-decoration: underline; }
    #footer .footer-block-top .footer-block-right .footer-menu ul li a:hover {
      text-decoration: none; }
    #footer .footer-block-top .footer-block-right .footer-menu ul li.hidden {
      width: 100%;
      height: 0;
      visibility: hidden; }
  #footer .footer-block-top .footer-block-left {
    float: left;
    width: 30%;
    margin-left: 2%;
    margin-bottom: 40px; }
    @media only screen and (max-width: 479px) {
      #footer .footer-block-top .footer-block-left {
        float: none;
        width: 90%;
        margin: 0 5%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #footer .footer-block-top .footer-block-left {
        float: none;
        width: 90%;
        margin: 0 5%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #footer .footer-block-top .footer-block-left {
        float: none;
        width: 90%;
        margin: 0 5%; } }
    #footer .footer-block-top .footer-block-left .footer-adress {
      margin: 100px 0px 30px 0px; }
    #footer .footer-block-top .footer-block-left .footer-adress p {
      color: #c7c7c7;
      font-size: 14px;
      line-height: 130%; }
    @media only screen and (max-width: 479px) {
      #footer .footer-block-top .footer-block-left .footer-social {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #footer .footer-block-top .footer-block-left .footer-social {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #footer .footer-block-top .footer-block-left .footer-social {
        margin-bottom: 30px; } }
  #footer .footer-block-top:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  #footer .footer-block-bottom {
    background: #000000;
    color: #c7c7c7;
    font-size: 12px; }
    #footer .footer-block-bottom .copyright {
      float: left;
      line-height: 60px;
      margin-left: 2%; }
    #footer .footer-block-bottom .footer-go-up {
      float: right;
      line-height: 60px;
      margin-right: 2%; }
    #footer .footer-block-bottom:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }

#about .padding {
  padding: 50px 20px; }

#about .show-center-text-hidden {
  text-align: center;
  margin-top: 40px; }
  #about .show-center-text-hidden.active {
    display: none; }

#about .show-center-text-hidden a {
  color: #d8b161;
  text-decoration: underline;
  padding: 10px 20px; }
  #about .show-center-text-hidden a:hover {
    text-decoration: none; }

#about .center-text-hidden {
  display: none; }

#about .center-text-hidden.open {
  display: block; }

/* #about */
#main-gallery .padding {
  padding-bottom: 20px; }

#main-gallery .owl-item {
  margin: 0px 1px; }

#main-gallery ul.proizvod-gallery {
  font-size: 0;
  margin-bottom: 40px; }

#main-gallery ul.proizvod-gallery li {
  display: inline-block;
  margin: 1px;
  width: calc(25% - 8px); }
  @media only screen and (max-width: 479px) {
    #main-gallery ul.proizvod-gallery li {
      width: calc(100% - 2px); } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #main-gallery ul.proizvod-gallery li {
      width: calc(50% - 2px); } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #main-gallery ul.proizvod-gallery li {
      width: calc(33.3% - 6px); } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #main-gallery ul.proizvod-gallery li {
      width: calc(33.3% - 6px); } }

#main-gallery ul.proizvod-gallery li img {
  max-width: 100%; }

#main-gallery ul.proizvod-owl li img {
  max-width: 100%; }

#main-gallery .show-all-gallery {
  text-align: center;
  margin: 40px 0px; }
  #main-gallery .show-all-gallery.active {
    display: none; }

#main-gallery .show-all-gallery a {
  color: #d8b161;
  text-decoration: underline;
  padding: 10px 20px; }
  #main-gallery .show-all-gallery a:hover {
    text-decoration: none; }

#main-gallery .show-all-gallery.active {
  display: none; }

#main-gallery ul.proizvod-gallery li a, #main-gallery ul.proizvod-owl li a {
  position: relative;
  display: block; }

#main-gallery ul.proizvod-gallery li a span, #main-gallery ul.proizvod-owl li a span {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../img/play-button-gold-2.png) center center;
  background-size: cover;
  transition: all 0.3s linear; }
  #main-gallery ul.proizvod-gallery li a span:hover, #main-gallery ul.proizvod-owl li a span:hover {
    background: url(../img/play-button-grey-2.png) center center;
    background-size: cover;
    transition: all 0.3s linear; }

#main-gallery .proizvod-gallery {
  display: none; }

#main-gallery .proizvod-gallery.open {
  display: block; }

#main-gallery .proizvod-owl.close {
  display: none !important; }

/*  #main-gallery */
#about .padding {
  padding: 0px 20px 50px 20px; }

#about .padding-top {
  padding-top: 50px; }

/* #about */
#category .padding {
  padding-bottom: 20px; }

#category ul {
  font-size: 0;
  margin-bottom: 40px; }

#category ul li {
  display: inline-block;
  width: calc(50% - 20px);
  margin: 10px;
  position: relative; }
  @media only screen and (max-width: 479px) {
    #category ul li {
      width: calc(100% - 20px); } }
  #category ul li h2 {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    line-height: 130%;
    z-index: 10;
    font-size: 18px;
    text-align: center; }
  #category ul li hr {
    margin-top: 4px;
    height: 1px;
    border: none;
    color: #fff;
    background: #fff;
    margin: 0 auto;
    width: 70%;
    transition: all 0.3s linear; }
  #category ul li i {
    display: block;
    height: 10px; }
  #category ul li b {
    display: inline-block; }
  #category ul li span {
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 5;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all 0.3s linear; }

#category ul li:hover hr {
  width: 100%; }

#category ul li:hover span {
  background: rgba(0, 0, 0, 0.3); }

#category ul li a {
  color: #fff; }

#category ul li img {
  max-width: 100%; }

#category ul li img {
  max-width: 100%; }

/*  #category */
#fotorama-gallery .padding {
  padding-bottom: 20px; }

#fotorama-gallery .fotorama-sm {
  display: none; }

@media only screen and (max-width: 479px) {
  #fotorama-gallery .fotorama-big {
    display: none; }
  #fotorama-gallery .fotorama-sm {
    display: block; } }

@media only screen and (min-width: 480px) and (max-width: 599px) {
  #fotorama-gallery .fotorama-big {
    display: none; }
  #fotorama-gallery .fotorama-sm {
    display: block; } }

/*  #fotorama-gallery */
#text-gallery .padding {
  padding: 20px 10px; }

#cart-gallery .cart-photobox {
  display: inline-block;
  margin: 1px;
  width: calc(25% - 8px); }
  @media only screen and (max-width: 479px) {
    #cart-gallery .cart-photobox {
      width: calc(100% - 2px); } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #cart-gallery .cart-photobox {
      width: calc(50% - 2px); } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #cart-gallery .cart-photobox {
      width: calc(33.3% - 6px); } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #cart-gallery .cart-photobox {
      width: calc(33.3% - 6px); } }
  #cart-gallery .cart-photobox img {
    max-width: 100%; }

#cart-gallery .cart-photobox a:not(:first-child) {
  display: none; }

#cart-gallery .cart-owl {
  width: 100%;
  margin: 0px;
  display: block;
  margin-bottom: 40px; }

#cart-gallery .cart-owl a:not(:first-child) {
  display: block; }

#cart-gallery .show-cart-gallery {
  text-align: center;
  margin-bottom: 20px; }
  #cart-gallery .show-cart-gallery a {
    color: #989898;
    text-decoration: none;
    border-bottom: 1px dashed #989898; }
  #cart-gallery .show-cart-gallery .close {
    display: none; }
  #cart-gallery .show-cart-gallery.active .open {
    display: none; }
  #cart-gallery .show-cart-gallery.active .close {
    display: inline-block; }

#cart-gallery .owl-item {
  margin: 0px 1px; }

#cart-gallery .owl-theme .owl-controls {
  margin-bottom: -22px; }
  @media only screen and (max-width: 479px) {
    #cart-gallery .owl-theme .owl-controls {
      margin-bottom: 0px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #cart-gallery .owl-theme .owl-controls {
      margin-bottom: 0px; } }

@media only screen and (max-width: 479px) {
  #cart-gallery .owl-item {
    text-align: center; } }

#cart-gallery .show-gallery .cart-owl {
  display: none !important; }

#cart-gallery .show-gallery.active .cart-owl {
  display: block !important; }

#cart-gallery .show-gallery.active .cart {
  display: none; }

@media only screen and (max-width: 479px) {
  #cart-gallery .show-cart-gallery {
    display: none; }
  #cart-gallery .cart-owl {
    display: block !important;
    margin-bottom: 20px; }
  #cart-gallery .show-gallery .cart-owl {
    display: block !important; }
  #cart-gallery .cart {
    display: none; } }

#inter {
  /* .right-content */ }
  #inter .padding {
    padding: 20px 0px; }
  #inter .cart-inter-title h2 {
    font-size: 18px;
    color: #d8b161;
    margin-bottom: 20px; }
    #inter .cart-inter-title h2 a {
      color: #d8b161; }
    #inter .cart-inter-title h2 hr {
      height: 1px;
      border: none;
      color: #717171;
      background: #717171;
      margin: 0 auto;
      width: 30%;
      transition: all 0.3s linear;
      text-align: left;
      /* для ie */
      margin: 0 auto 0 0;
      /* для остальных браузеров */ }
    #inter .cart-inter-title h2 i {
      display: block;
      height: 10px; }
    #inter .cart-inter-title h2 b {
      display: inline-block; }
    #inter .cart-inter-title h2 :hover hr {
      width: 100%; }
  #inter .cart-inter {
    margin-bottom: 20px;
    background: #0f0f0f; }
    #inter .cart-inter:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
  #inter .cart-inter-left {
    width: 64%;
    float: left; }
    #inter .cart-inter-left .padding {
      padding: 20px 0px 0px 20px; }
    @media only screen and (max-width: 479px) {
      #inter .cart-inter-left {
        float: none;
        width: 100%; }
        #inter .cart-inter-left .padding {
          padding: 10px 0px 0px 0px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #inter .cart-inter-left {
        float: none;
        width: 100%; }
        #inter .cart-inter-left .padding {
          padding: 10px 0px 0px 0px; } }
  #inter .cart-inter-right {
    width: 34%;
    float: right; }
    #inter .cart-inter-right .padding {
      padding: 20px 10px 0px 20px; }
    @media only screen and (max-width: 479px) {
      #inter .cart-inter-right {
        float: none;
        width: 98%; }
        #inter .cart-inter-right .padding {
          padding-top: 10px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #inter .cart-inter-right {
        float: none;
        width: 98%; }
        #inter .cart-inter-right .padding {
          padding-top: 10px; } }
    #inter .cart-inter-right p {
      line-height: 130%; }

/*  #inter */
/* @import "button"; */
a {
  color: #d8b161; }

.left {
  float: left; }

.right {
  float: right; }

.mp-o {
  margin: 2px 0;
  padding: 2px 0; }

.center {
  text-align: center; }

.block {
  width: 100%;
  box-sizing: content-box; }
  .block .pod-block {
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
    @media only screen and (max-width: 479px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 980px) {
      .block .pod-block {
        max-width: 1200px; } }

.left-content {
  width: 29%;
  float: left; }
  .left-content .padding {
    padding: 2% 1% 0% 3%; }
  @media only screen and (max-width: 479px) {
    .left-content {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .left-content {
      float: none;
      width: 100%; } }

.right-content {
  width: 69%;
  float: right; }
  .right-content .padding {
    padding: 2% 0% 0% 1%; }
  @media only screen and (max-width: 479px) {
    .right-content {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content {
      float: none;
      width: 100%; } }
  .right-content p {
    line-height: 130%; }

/* .right-content */
.images-100 {
  max-width: 100%; }

.title {
  text-align: center;
  margin-bottom: 30px; }

.title h1 {
  font-size: 30px;
  color: #d8b161;
  margin-bottom: 20px; }
  .title h1 hr {
    height: 1px;
    border: none;
    color: #717171;
    background: #717171;
    margin: 0 auto;
    width: 70%; }
  .title h1 i {
    display: block;
    height: 30px; }
  .title h1 b {
    display: inline-block; }

/* .title > hr { text-align: center;
height: 1px;
border: none;
color: #717171;
background: #717171;
margin: 0 auto;
width:120px;
} */
.big-block-text p {
  color: #989898;
  font-size: 18px;
  line-height: 130%;
  margin-bottom: 10px; }
  @media only screen and (max-width: 479px) {
    .big-block-text p {
      font-size: 16px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .big-block-text p {
      font-size: 16px; } }

.block-text p {
  color: #989898;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 10px; }
  @media only screen and (max-width: 479px) {
    .block-text p {
      font-size: 13px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .block-text p {
      font-size: 13px; } }

#bottom-link {
  text-align: center;
  margin: 40px 0px; }
  #bottom-link a {
    display: inline-block;
    margin: 0px 20px 20px 20px;
    padding: 30px 40px;
    border: 1px solid #d8b161;
    color: #d8b161;
    font-size: 30px; }
    #bottom-link a:hover {
      border: 1px solid #989898;
      color: #989898; }

/* #bottom-link */
.font-null {
  font-size: 0; }

span.skype_c2c_container img.skype_c2c_logo_img {
  display: none !important; }

.main-block {
  position: absolute;
  bottom: 200px;
  left: 0px;
  width: 100%;
  z-index: 90; }
  @media only screen and (max-width: 479px) {
    .main-block {
      bottom: 150px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .main-block {
      bottom: 150px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .main-block {
      bottom: 150px; } }

.main-block-podblock {
  width: 700px;
  background: rgba(0, 0, 0, 0.6);
  margin: 100px auto 0px auto;
  border-radius: 20px; }
  @media only screen and (max-width: 479px) {
    .main-block-podblock {
      width: 90%;
      margin: 0 5%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .main-block-podblock {
      width: 90%;
      margin: 0 5%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .main-block-podblock {
      width: 90%;
      margin: 0 5%; } }
  .main-block-podblock .padding {
    padding: 0px 20px; }

.main-block-logo {
  margin-top: 40px;
  text-align: center; }

.main-block-logo img {
  max-width: 100%; }

.main-block-text {
  margin-top: 40px;
  color: #fff;
  text-align: center; }

.main-block-menu {
  margin-top: 40px;
  text-align: justify;
  /* Лекарство для IE6-7*/
  text-justify: newspaper; }

.main-block-menu a {
  display: inline-block;
  line-height: 130%;
  margin: 0 25px 0 25px;
  padding: 10px 20px;
  color: #d8b161;
  border: 1px solid #d8b161;
  font-size: 18px; }
  @media only screen and (max-width: 479px) {
    .main-block-menu a {
      display: block;
      width: 70%;
      margin: 0 5% 20px 5%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .main-block-menu a {
      display: block;
      width: 70%;
      margin: 0 5% 20px 5%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .main-block-menu a {
      display: block;
      width: 70%;
      margin: 0 5% 20px 5%; } }

.main-block-menu a:hover {
  color: #fff;
  border: 1px solid #fff; }

.main-block-menu a.hidden {
  width: 100%;
  height: 0;
  visibility: hidden; }

#breadcrumb {
  text-align: center;
  color: #989898;
  margin: 40px 0px; }
  #breadcrumb .padding {
    padding: 0px 20px; }
    @media only screen and (max-width: 479px) {
      #breadcrumb .padding {
        padding: 0px 10px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #breadcrumb .padding {
        padding: 0px 10px; } }
  #breadcrumb .breadcrumb-center {
    display: inline-block;
    line-height: 130%; }
  #breadcrumb a {
    color: #989898;
    text-decoration: underline; }
    #breadcrumb a:hover {
      text-decoration: none; }
