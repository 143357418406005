

#fotorama-gallery {

.padding {padding-bottom:20px;}

.fotorama-sm {display:none;}

				// < 479
				@include respond-to(handhelds) { 
				.fotorama-big {display:none;}
				.fotorama-sm {display:block;}
				}
				// 480 > 599
				@include respond-to(wide-handhelds) {
				.fotorama-big {display:none;}
				.fotorama-sm {display:block;}
				 }


} /*  #fotorama-gallery */

#text-gallery {

.padding {padding:20px 10px;}


}


#cart-gallery {

.cart-photobox {

display: inline-block; margin:1px; width:calc(25% - 8px);

				// < 479
				@include respond-to(handhelds) { width:calc(100% - 2px); }
				// 480 > 599
				@include respond-to(wide-handhelds) { width:calc(50% - 2px); }
				// 600 > 767
				@include respond-to(small-tablets) { width:calc(33.3% - 6px); }
				// 768 > 979
				@include respond-to(tablets) { width:calc(33.3% - 6px); }

img {max-width:100%;}

}	

.cart-photobox a:not(:first-child) {display:none;}	

.cart-owl {width:100%; margin:0px; display:block; margin-bottom:40px;}
.cart-owl a:not(:first-child) {display:block;}


.show-cart-gallery { text-align:center; margin-bottom:20px;

 a {color:#989898; text-decoration:none; border-bottom:1px dashed #989898;}
	.close {display:none;}

&.active {
	.open {display:none;}
.close {display:inline-block;}

}

}

.owl-item {margin:0px 1px;}
.owl-theme .owl-controls {margin-bottom:-22px;

				// < 479
				@include respond-to(handhelds) { margin-bottom:0px; }
				// 480 > 599
				@include respond-to(wide-handhelds) { margin-bottom:0px; }
}

				// < 479
				@include respond-to(handhelds) { .owl-item{text-align:center;} }

.show-gallery {.cart-owl{display:none!important;}}
.show-gallery.active {.cart-owl{display:block!important;} .cart{display:none;}}

@include respond-to(handhelds) { 
	.show-cart-gallery{display:none;} 
	.cart-owl{display:block!important; margin-bottom:20px;} 
	.show-gallery {.cart-owl{display:block!important;} }
	.cart{display:none;} 
}


}

