
#header.header-fon-big {background: url('../img/header-fon-big.jpg') bottom center;}
#header.header-fon-sm {background: url('../img/header-fon-big.jpg') bottom center;}


#header{
	min-height: 220px;

	// Для телефонов
	@include respond-to(handhelds) { height: 220px;  }
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { height: 320px;  }
	// Для маленьких планшетов
	@include respond-to(small-tablets) { height: 320px;  }
	// Для планшетов
	@include respond-to(tablets) { &.header-fon-big {height: 480px;}  }
	// Для экранов
	@include respond-to(display) { &.header-fon-big {height: 600px;}  }

.header-line { background:rgba(0, 0, 0, 0.6); }

.pod-block{
	@include pod-block-100;

.padding {padding: 0px 20px;}



.header-logo {float:left; margin: 10px 0px;
		// Для телефонов
	@include respond-to(handhelds) { width:160px;  }
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { width:220px;  }
	// Для маленьких планшетов
	@include respond-to(small-tablets) { width:220px;  }
	// Для планшетов
	@include respond-to(tablets) { width:220px; }
}	
.header-logo img {width: 100%;}

.header-menu {float: right;
	// Для телефонов
	@include respond-to(handhelds) { display: none;  }
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { display: none;  }
	// Для маленьких планшетов
	@include respond-to(small-tablets) { display: none;  }
}
.header-menu .close_menu {display: none;
	// Для телефонов
	@include respond-to(handhelds) { display: block;  }
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { display: block;  }
	// Для маленьких планшетов
	@include respond-to(small-tablets) { display: block;  }

}


.header-menu {text-align: justify;
        /* Лекарство для IE6-7*/
        text-justify: newspaper; 
          
					}

		.header-menu ul li {display: inline-block; line-height: 100px; margin-right:10px; 
			@include respond-to(tablets) { margin-right:5px;   } }
		.header-menu ul li a {color:#c7c7c7; line-height: 100px; font-size: 16px; text-decoration: none; 
			@include respond-to(tablets) { font-size: 14px;  }
		}
		.header-menu ul li a:hover {border-bottom:1px solid #c7c7c7;} 

		.header-menu ul li.hidden {
                width: 100%;
                height: 0;
                visibility: hidden;
					}
	
.header-sm_btns	{display: none;

&>a { color:#c7c7c7; line-height: 100px; font-size: 18px; text-align: center; 

@include respond-to(handhelds) { line-height: 75px; }
	}

	// Для телефонов
	@include respond-to(handhelds) { display: block; float: right; }
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { display: block; float: right;  }
	// Для маленьких планшетов
	@include respond-to(small-tablets) { display: block;  float: right; }
} 

} /* header-sm_btns */

} /* #header*/


@media only screen and (max-width: 767px) {


#top-menu.header-menu.active {position:absolute; top:77px; left:0px; width:100%;  text-align: justify;
        /* Лекарство для IE6-7*/
        text-justify: newspaper; background:rgba(0, 0, 0, 0.8); z-index: 999; padding-top: 20px;
	// Для телефонов
	@include respond-to(handhelds) { display: block;  }
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { display: block;  top:102px; }
	// Для маленьких планшетов
	@include respond-to(small-tablets) { display: block;  top:102px; }


    }




#top-menu.header-menu.active ul li { display: inline-block; line-height: 32px; margin: 0 25px 0 25px;   font-size: 18px;

			// Для телефонов
	@include respond-to(handhelds) {display: block; width:70%; margin:0 15% 20px 15%; };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { display: block; width:70%; margin:0 15% 20px 15%; };
	// Для маленьких планшетов
	@include respond-to(small-tablets) { display: block; width:70%; margin:0 15% 20px 15%; };

  }

#top-menu.header-menu.active ul li a  {border: 1px solid #d8b161; display:block; padding: 10px 10px; color:#d8b161; line-height: 32px; width: calc(100% - 20px); text-decoration: none; background: #000;} 
 
#top-menu.header-menu.active  ul li a:hover {color:#fff;  border: 1px solid #fff; }
  
#top-menu.header-menu.active  a.hidden {
                width: 100%;
                height: 0;
                visibility: hidden;
					}

}

/*Анимация гамбургера*/

.header-sm_btns_text { display: inline-block; vertical-align: middle; }

.cmn-toggle-switch { vertical-align: middle;
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 15px;
  height: 13px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  -webkit-transition: background 0.3s;
          transition: background 0.3s;
}

.cmn-toggle-switch:focus {
  outline: none;
}


.cmn-toggle-switch span {
  display: block;
  position: absolute;
  top: 5px;
  left: 0px;
  right: 0px;
  height: 3px;
  background: #c7c7c7;
}

.cmn-toggle-switch span::before,
.cmn-toggle-switch span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #c7c7c7;
  content: "";
}

.cmn-toggle-switch span::before {
  top: -5px;
}

.cmn-toggle-switch span::after {
  bottom: -5px;
}




/**
 * Стиль 2
 * 
 * «Гамбургер» превращается в «x» (класс htx). 
 * Берется исходное состояние, затем полоски плавно перемещаются в центр 
 * и трансформируются в «x».
*/
.cmn-toggle-switch__htx {
   background-color: rgba(0,0,0,0); 
}

.cmn-toggle-switch__htx span {
/*   -webkit-transition: background 0 0.3s;
        transition: background 0 0.3s; */
}

.cmn-toggle-switch__htx span::before,
.cmn-toggle-switch__htx span::after {
  -webkit-transition-duration: 0.3s, 0.3s;
          transition-duration: 0.3s, 0.3s;
  -webkit-transition-delay: 0.3s, 0;
          transition-delay: 0.3s, 0;
}

.cmn-toggle-switch__htx span::before {
  -webkit-transition-property: top, -webkit-transform;
          transition-property: top, transform;
}

.cmn-toggle-switch__htx span::after {
  -webkit-transition-property: bottom, -webkit-transform;
          transition-property: bottom, transform;
}

/* активное состояние, т.е. меню открыто */
.active > .cmn-toggle-switch__htx {
   background-color: rgba(0,0,0,0); 
}

.active > .cmn-toggle-switch__htx span {
  background: none;
}

.active > .cmn-toggle-switch__htx span::before {
  top: 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.active > .cmn-toggle-switch__htx span::after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.active > .cmn-toggle-switch__htx span::before,
.active > .cmn-toggle-switch__htx span::after {
  -webkit-transition-delay: 0, 0.3s;
          transition-delay: 0, 0.3s;
}


