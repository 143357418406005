

#inter {

.padding {padding:20px 0px;}

.cart-inter-title h2 {font-size: 18px; color: #d8b161;  margin-bottom: 20px;
a {color: #d8b161;}
hr {height: 1px; border: none; color: #717171; background: #717171; margin: 0 auto; width:30%; transition: all 0.3s linear;
text-align: left; /* для ie */
margin: 0 auto 0 0; /* для остальных браузеров */
}
i {display:block; height:10px;}
b {display:inline-block;}

:hover {
 	hr {width:100%;}
 	}

 }


.cart-inter {@include clearfix; margin-bottom:20px; background:#0f0f0f;}

.cart-inter-left {width: 64%; float: left; 
.padding { padding: 20px 0px 0px 20px;}

// Для телефонов до 480
	@include respond-to(handhelds) { float: none; width: 100%; 
			.padding {padding:10px 0px 0px 0px;}
	};
	// Для телефонов с широким экраном до 767
	@include respond-to(wide-handhelds) { float: none; width: 100%; 
			.padding {padding:10px 0px 0px 0px;}
	};
	// Для планшетов до 979
	@include respond-to(tablets) {  };
	// Для экранов от 980
	@include respond-to(display) {  };
		}




.cart-inter-right {width: 34%; float: right;
.padding { padding: 20px 10px 0px 20px;}
			// Для телефонов до 480
			@include respond-to(handhelds) { float: none; width: 98%; 
			.padding {padding-top:10px;}
			};
			// Для телефонов с широким экраном до 767
			@include respond-to(wide-handhelds) { float: none; width: 98%; 
			.padding {padding-top:10px;}
			};


p {line-height: 130%;}

} /* .right-content */

} /*  #inter */



