#about {

.padding {padding: 0px 20px 50px 20px; }
.padding-top {padding-top: 50px;} 


} /* #about */



#category {

.padding {padding-bottom:20px;}

 ul {font-size: 0; margin-bottom: 40px;}
 ul li {display: inline-block; width:calc(50% - 20px); margin:10px; position:relative;

				// < 479
				@include respond-to(handhelds) { width:calc(100% - 20px); }
				// 480 > 599
				@include respond-to(wide-handhelds) { }

h2 {position:absolute; top: 50%; left: 0; width:100%; line-height:130%; z-index:10; font-size:18px; text-align:center;}
hr {margin-top:4px; height: 1px; border: none; color: #fff; background: #fff; margin: 0 auto; width:70%; transition: all 0.3s linear;}
i {display:block; height:10px;}
b {display:inline-block;}
span {display:block; width:100%; height:100%; background:rgba(0, 0, 0, 0.5); z-index:5; position:absolute; top:0px; left:0px; transition: all 0.3s linear; }

}

 ul li:hover {
 	hr {width:100%;}
	span {background:rgba(0, 0, 0, 0.3);}	
}


 ul li a {color:#fff;}
 ul li img {max-width: 100%;}
 ul li img {max-width: 100%;}



} /*  #category */




