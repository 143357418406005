#footer{border-top: 2px solid #5a5a5a; background: #0d0d0d;
	// Для телефонов
	@include respond-to(handhelds) { };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) {  };
	// Для маленьких планшетов
	@include respond-to(small-tablets) {  };
	// Для планшетов
	@include respond-to(tablets) {  };
	// Для экранов
	@include respond-to(display) {  };

.footer-block-top {



	.footer-block-right {float: right; width:65%; margin-right: 2%;
	

			// Для телефонов
	@include respond-to(handhelds) {float: none; width:90%; margin:0 5%; };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { float: none; width:90%; margin:0 5%; };
	// Для маленьких планшетов
	@include respond-to(small-tablets) { float: none; width:90%; margin:0 5%; };
		
		.footer-logo {text-align: center; margin:40px 0px 60px 0px;}
		.footer-logo img {max-width:100%;}


		.footer-menu {text-align: justify;
        /* Лекарство для IE6-7*/
        text-justify: newspaper;

        margin-bottom: -75px;

        	// Для планшетов
	@include respond-to(tablets) { margin-bottom: 40px; };
	// Для экранов
	@include respond-to(display) { margin-bottom: 40px; };
        
					}

		.footer-menu ul li {display: inline-block; line-height: 130%; margin-right:10px;}
		.footer-menu ul li a {color:#c7c7c7; line-height: 130%; font-size: 16px; text-decoration: underline;}
		.footer-menu ul li a:hover {text-decoration: none;} 

		.footer-menu ul li.hidden {
                width: 100%;
                height: 0;
                visibility: hidden;
					}

						} //footer-block-right

	.footer-block-left {float: left; width:30%; margin-left: 2%;

	// Для телефонов
	@include respond-to(handhelds) {float: none; width:90%; margin:0 5%; };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { float: none; width:90%; margin:0 5%; };
	// Для маленьких планшетов
	@include respond-to(small-tablets) { float: none; width:90%; margin:0 5%; };

	        margin-bottom: 40px;


		
		.footer-adress {margin: 100px 0px 30px 0px;}
		.footer-adress p {color:#c7c7c7; font-size: 14px; line-height: 130%;}

		.footer-social {

	// Для телефонов
	@include respond-to(handhelds) {margin-bottom: 30px; };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { margin-bottom: 30px; };
	// Для маленьких планшетов
	@include respond-to(small-tablets) { margin-bottom: 30px; };

		}

						} //footer-block-left

@include clearfix;
					}


.footer-block-bottom {background: #000000; color:#c7c7c7; font-size: 12px; 


.copyright {float:left; line-height: 60px; margin-left: 2%;}
.footer-go-up {float:right;  line-height: 60px; margin-right: 2%; }


@include clearfix;
}	




}//#footer